@tailwind base;
@tailwind components;
@tailwind utilities;
html,
body {
  font-family: "Montserrat", sans-serif;
}
::-webkit-scrollbar {
  display: none;
}
.shadowCustom {
  box-shadow: 12px 12px 0px -3px #808080;
  -webkit-box-shadow: 12px 12px 0px -3px #808080;
  -moz-box-shadow: 12px 12px 0px -3px #808080;
}
